@media only screen and (min-width: 1200px) {
.snake-change-level {
  width: 150px;
  height: 30px;
  background-color: DodgerBlue;
  margin-left: 25%;
  margin-top: 10px;
  padding-left: 50px;
  font-size: 15px;
  cursor: pointer;
  font-weight: bold;
}
.snake-user-input {
  position: absolute;
  top: 200px;
  left: 39%;
  width: 300px;
  height: 200px;
  background-color: lightpink;
  border: 2px solid blue;
  border-radius: 30px;
}
.snake-user-name {
  display: block;
  width: 250px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  border: 1px solid black;
  text-align: center;
  font-size: 15px;
}
.snake-start-button {
  margin-left: 35%;
  margin-top: 20px;
  width: 100px;
  height: 30px;
  background-color: black;
  color: white;
  font-size: 15px;
  cursor: pointer;
  outline: none;
}
.snake-board {
  position: relative;
  width: 500px;
  height: 500px;  
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid brown;
  background-color: white;
}
.snake-dot {
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: blue;
  border: 1px solid white;
  z-index: 2;
}
div.snake-dot:last-child {
  background-color: purple;
}
.snake-food {
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: red;
  border: 1px solid white;
  z-index: 1;
}
.snake-score-label {
 position: absolute;
 width: 100px;
 height: 30px;
 color: white;
 font-size: 19px;
 font-weight: bold;
 top: 55%;
 margin: 15px;
}
.snake-score {
  position: absolute;
 width: 100px;
 height: 30px;
 color: red;
 font-size: 17px; 
 top: 60%;
 margin: 15px;
}
.snake-level-label {
 position: absolute;
 width: 100px;
 height: 30px;
 color: white;
 font-size: 19px;
 font-weight: bold;
 top: 35%;
 margin: 15px;
}
.snake-level {
 position: absolute;
 width: 100px;
 height: 30px;
 color: red;
 font-size: 17px; 
 top: 38%;
 margin: 15px;
 padding: 15px;
}
.snake-left-button {
  position: absolute;
  top: 45%;
  right: 26%;
  width: 60px;
  font-size: 12px;
}
.snake-right-button {
  position: absolute;
  width: 60px;
  top: 45%;
  right: 20%;
  font-size: 12px;
}
.snake-up-button {
  position: absolute;
  top: 37%;
  right: 23%;
  width: 60px;
  font-size: 12px;
}
.snake-down-button {
  position: absolute;
  top: 53%;
  right: 23%;
  width: 60px;
  font-size: 12px;
}
.snake-newgame {
  position: absolute;
  top: 0;
  left: 31.8%;
  z-index: 3;  
}
}
@media only screen and (max-width: 600px) {
  .snake-change-level {
  width: 140px;
  height: 30px;
  background-color: DodgerBlue;
  margin-left: 21%;
  margin-top: 10px;
  padding-left: 50px;
  font-size: 15px;
  cursor: pointer;
  font-weight: bold;
}
.snake-user-input {
  position: absolute;
  top: 150px;
  left: 26%;
  width: 220px;
  height: 200px;
  background-color: lightpink;
  border: 2px solid blue;
  border-radius: 30px;
}
.snake-user-name {
  display: block;
  width: 210px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  border: 1px solid black;
  text-align: center;
  font-size: 15px;
}
.snake-start-button {
  margin-left: 35%;
  margin-top: 20px;
  width: 100px;
  height: 30px;
  background-color: black;
  color: white;
  font-size: 15px;
  cursor: pointer;
  outline: none;
}
  .snake-board {
  position: relative;
  width: 280px;
  height: 280px;  
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  border: 2px solid brown;
  background-color: white;
}
.snake-dot {
  position: absolute;
  width: 7px;
  height: 7px;
  background-color: blue;
  border: 1px solid white;
  z-index: 2;
}
.snake-food {
  position: absolute;
  width: 7px;
  height: 7px;
  background-color: red;
  border: 1px solid white;
  z-index: 1;
}
div.snake-dot:last-child {
  background-color: purple;
}

.snake-score-label {
 position: relative;
 width: 50px;
 height: 20px;
 color: white;
 font-size: 14px;
 font-weight: bold;
 top: 55%;
 left: 10%;
}
.snake-score {
  position: relative;
 width: 50px;
 height: 20px;
 color: red;
 font-size: 14px; 
 top: 58%;
 left: 10%;
}
.snake-level-label {
 position: absolute;
 width: 50px;
 height: 20px;
 color: white;
 font-size: 14px;
 font-weight: bold;
 top: 365px;
 right: 15%;;
}
.snake-level {
 position: absolute;
 width: 50px;
 height: 20px;
 color: red;
 font-size: 14px; 
 top: 392px;
 right: 15%;
}
.snake-left-button {
  position: absolute;
  top: 383px;
  right: 53%;
  width: 40px;
  font-size: 10px;
}
.snake-right-button {
  position: absolute;
  width: 40px;
  top: 383px;
  right: 29%;
  font-size: 10px;
}
.snake-up-button {
  position: absolute;
  top: 355px;
  right: 41%;
  width: 40px;
  font-size: 10px;
}
.snake-down-button {
  position: absolute;
  top: 410px;
  right: 41%;
  width: 40px;
  font-size: 10px;
}
.snake-newgame {
  position: absolute;
  top: 0;
  left: 5%;
  z-index: 3;  
}
}

@media only screen and (min-width: 600px) {
.snake-change-level {
  width: 150px;
  height: 30px;
  background-color: DodgerBlue;
  margin-left: 25%;
  margin-top: 10px;
  padding-left: 50px;
  font-size: 15px;
  cursor: pointer;
  font-weight: bold;
}
.snake-user-input {
  position: absolute;
  top: 160px;
  right: 35%;  
  width: 260px;
  height: 190px;
  background-color: lightpink;
  border: 2px solid blue;
  border-radius: 30px;
}
.snake-user-name {
  display: block;
  width: 250px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  border: 1px solid black;
  text-align: center;
  font-size: 15px;
}
.snake-start-button {
  margin-left: 35%;
  margin-top: 20px;
  width: 100px;
  height: 30px;
  background-color: black;
  color: white;
  font-size: 15px;
  cursor: pointer;
  outline: none;
}
.snake-board {
  position: relative;
  width: 320px;
  height: 320px;  
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid brown;
  background-color: white;
}
.snake-dot {
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: blue;
  border: 1px solid white;
  z-index: 2;
}
div.snake-dot:last-child {
  background-color: purple;
}
.snake-food {
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: red;
  border: 1px solid white;
  z-index: 1;
}
.snake-score-label {
 position: absolute;
 width: 50px;
 height: 20px;
 color: white;
 font-size: 14px;
 font-weight: bold;
 top: 50%;
 margin: 10px;
}
.snake-score {
 position: absolute;
 width: 50px;
 height: 20px;
 color: red;
 font-size: 14px; 
 top: 55%;
 margin: 10px;
}
.snake-level-label {
 position: absolute;
 width: 50px;
 height: 20px;
 color: white;
 font-size: 14px;
 font-weight: bold;
 top: 25%;
 margin: 10px;
}
.snake-level {
 position: absolute;
 width: 50px;
 height: 20px;
 color: red;
 font-size: 14px; 
 top: 28%;
 margin: 8px;
 padding: 8px;
}
.snake-left-button {
  position: absolute;
  top: 40%;
  right: 16.6%;
  width: 30px;
  font-size: 10px;
}
.snake-right-button {
  position: absolute;
  width: 30px;
  top: 40%;
  right: 8.5%;
  font-size: 10px;
}
.snake-up-button {
  position: absolute;
  top: 30%;
  right: 12%;
  width: 30px;
  font-size: 10px;
}
.snake-down-button {
  position: absolute;
  top: 50%;
  right: 12%;
  width: 30px;
  font-size: 10px;
}
.snake-newgame {
  position: absolute;
  top: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 3;  
}
}

@media only screen and (min-width: 768px) {
.snake-change-level {
  width: 150px;
  height: 30px;
  background-color: DodgerBlue;
  margin-left: 25%;
  margin-top: 10px;
  padding-left: 50px;
  font-size: 15px;
  cursor: pointer;
  font-weight: bold;
}
.snake-user-input {
  position: absolute;
  top: 160px;
  right: 35%;  
  width: 270px;
  height: 190px;
  background-color: lightpink;
  border: 2px solid blue;
  border-radius: 30px;
}
.snake-user-name {
  display: block;
  width: 250px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  border: 1px solid black;
  text-align: center;
  font-size: 15px;
}
.snake-start-button {
  margin-left: 35%;
  margin-top: 20px;
  width: 100px;
  height: 30px;
  background-color: black;
  color: white;
  font-size: 15px;
  cursor: pointer;
  outline: none;
}
.snake-board {
  position: relative;
  width: 320px;
  height: 320px;  
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid brown;
  background-color: white;
}
.snake-dot {
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: blue;
  border: 1px solid white;
  z-index: 2;
}
div.snake-dot:last-child {
  background-color: purple;
}
.snake-food {
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: red;
  border: 1px solid white;
  z-index: 1;
}
.snake-score-label {
 position: absolute;
 width: 50px;
 height: 20px;
 color: white;
 font-size: 14px;
 font-weight: bold;
 top: 50%;
 margin: 10px;
}
.snake-score {
 position: absolute;
 width: 50px;
 height: 20px;
 color: red;
 font-size: 14px; 
 top: 55%;
 margin: 10px;
}
.snake-level-label {
 position: absolute;
 width: 50px;
 height: 20px;
 color: white;
 font-size: 14px;
 font-weight: bold;
 top: 25%;
 margin: 10px;
}
.snake-level {
 position: absolute;
 width: 50px;
 height: 20px;
 color: red;
 font-size: 14px; 
 top: 28%;
 margin: 8px;
 padding: 8px;
}
.snake-left-button {
  position: absolute;
  top: 40%;
  right: 23.6%;
  width: 30px;
  font-size: 10px;
}
.snake-right-button {
  position: absolute;
  width: 30px;
  top: 40%;
  right: 19%;
  font-size: 10px;
}
.snake-up-button {
  position: absolute;
  top: 30%;
  right: 21%;
  width: 30px;
  font-size: 10px;
}
.snake-down-button {
  position: absolute;
  top: 50%;
  right: 21%;
  width: 30px;
  font-size: 10px;
}
.snake-newgame {
  position: absolute;
  top: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 3;  
}
}

@media only screen and (min-width: 992px) {
.snake-change-level {
  width: 150px;
  height: 30px;
  background-color: DodgerBlue;
  margin-left: 25%;
  margin-top: 10px;
  padding-left: 50px;
  font-size: 15px;
  cursor: pointer;
  font-weight: bold;
}
.snake-user-input {
  position: absolute;
  top: 170px;
  margin: auto;
  width: 290px;
  height: 200px;
  background-color: lightpink;
  border: 2px solid blue;
  border-radius: 30px;
}
.snake-user-name {
  display: block;
  width: 250px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  border: 1px solid black;
  text-align: center;
  font-size: 15px;
}
.snake-start-button {
  margin-left: 35%;
  margin-top: 20px;
  width: 100px;
  height: 30px;
  background-color: black;
  color: white;
  font-size: 15px;
  cursor: pointer;
  outline: none;
}
.snake-board {
  position: relative;
  width: 400px;
  height: 400px;  
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid brown;
  background-color: white;
}
.snake-dot {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: blue;
  border: 1px solid white;
  z-index: 2;
}
div.snake-dot:last-child {
  background-color: purple;
}
.snake-food {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: red;
  border: 1px solid white;
  z-index: 1;
}
.snake-score-label {
 position: absolute;
 width: 90px;
 height: 30px;
 color: white;
 font-size: 19px;
 font-weight: bold;
 top: 50%;
 margin: 15px;
}
.snake-score {
 position: absolute;
 width: 90px;
 height: 30px;
 color: red;
 font-size: 17px; 
 top: 55%;
 margin: 15px;
}
.snake-level-label {
 position: absolute;
 width: 90px;
 height: 30px;
 color: white;
 font-size: 19px;
 font-weight: bold;
 top: 25%;
 margin: 15px;
}
.snake-level {
 position: absolute;
 width: 90px;
 height: 30px;
 color: red;
 font-size: 17px; 
 top: 28%;
 margin: 15px;
 padding: 15px;
}
.snake-left-button {
  position: absolute;
  top: 40%;
  right: 25%;
  width: 40px;
  font-size: 11px;
}
.snake-right-button {
  position: absolute;
  width: 40px;
  top: 40%;
  right: 19%;
  font-size: 12px;
}
.snake-up-button {
  position: absolute;
  top: 30%;
  right: 22%;
  width: 40px;
  font-size: 11px;
}
.snake-down-button {
  position: absolute;
  top: 50%;
  right: 22%;
  width: 40px;
  font-size: 11px;
}
.snake-newgame {
  position: absolute;
  top: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 3;  
}
}