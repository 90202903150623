.nav-header {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: purple;
  position: fixed;
  width: 100%;
}
.nav-pages {
  float: right;
  border-right: 1px solid #bbb;
}
.nav-pages:last-child {
  border-right: none;
  float: left;
  font-size: 20px;
}
.nav-pages .nav-page {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
}
@media only screen and (max-width: 768px) {
  .nav-header {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: purple;
  position: fixed;
  width: 100%;
}
.nav-pages {
  float: right;
  border-right: 0.1px solid #bbb;
  font-size: 11px;
}
.nav-pages:last-child {
  border-right: none;
  float: left;
  font-size: 14px;
}
.nav-pages .nav-page {
  display: block;
  color: white;
  text-align: center;
  padding: 8px 10px;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
}

}