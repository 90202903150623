
.wheel-part:first-child .text {
  background-color: violet;
}
.wheel-part:nth-child(2) .text {
  background-color: indigo;
}
.wheel-part:nth-child(3) .text {
  background: blue;
}
.wheel-part:nth-child(4) .text {
  background: green;
}
.wheel-part:nth-child(5) .text {
  background: yellow;
}
.wheel-part:nth-child(6) .text {
  background: orange;
}
.wheel-part:nth-child(7) .text {
  background: red;
}
.wheel-part:nth-child(8) .text {
  background: violet;
}
.wheel-part:nth-child(9) .text {
  background: indigo;
}
.wheel-part:nth-child(10) .text {
  background: blue;
}
.wheel-part:nth-child(11) .text {
  background: green;
}
.wheel-part:nth-child(12) .text {
  background: yellow;
}
.wheel-part:first-child {
  transform: rotate(0deg) skewY(-60deg);
}
.wheel-part:nth-child(2) {
  transform: rotate(30deg) skewY(-60deg);
}
.wheel-part:nth-child(3) {
  transform: rotate(60deg) skewY(-60deg);
}
.wheel-part:nth-child(4) {
  transform: rotate(90deg) skewY(-60deg);
}
.wheel-part:nth-child(5) {
  transform: rotate(120deg) skewY(-60deg); 
}
.wheel-part:nth-child(6) {
  transform: rotate(150deg) skewY(-60deg);
}
.wheel-part:nth-child(7) {
  transform: rotate(180deg) skewY(-60deg);
}
.wheel-part:nth-child(8) {
  transform: rotate(210deg) skewY(-60deg);
}
.wheel-part:nth-child(9) {
  transform: rotate(240deg) skewY(-60deg);
}
.wheel-part:nth-child(10) {
  transform: rotate(270deg) skewY(-60deg);
}
.wheel-part:nth-child(11) {
  transform: rotate(300deg) skewY(-60deg);
}
.wheel-part:nth-child(12) {
  transform: rotate(330deg) skewY(-60deg);
}
.circle {
  width: 25em;
  height: 25em;
  border: 1px solid black;
  position: relative;
  padding: 0;
  margin: 1em auto;
  border-radius: 50%;
  list-style: none;
  overflow: hidden;  
}

.start-rotate {
  animation: start-rotate 1s linear infinite;
}
@keyframes start-rotate {
  100% {
    transform: rotate(360deg);
  }
}
.stop-rotate
{
    animation-play-state: paused;
}
.wheel-part {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 50%;
  transform-origin: 0% 100%;
}
.text {
  position: absolute;
  left: -100%;
  width: 200%;
  height: 200%;
  text-align: center;
  display: block;
  transform: skewY(60deg) rotate(15deg);
  padding-top: 20px;  
  font-weight: bold;
  font-size: 18px;
}
.triangle-down {
  position: absolute;
	width: 0;
	height: 0;
	border-left: 15px solid transparent;
	border-right: 15px solid transparent;
	border-top: 50px solid black;
  left: 50%;
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
  top: 55px;
}
.spin-button {
 position: absolute;
 width: 120px;
 height: 40px;
 background-color: blue;
 border-radius: 30px;
 cursor: pointer;
 outline: none;
 color: white;
 font-size: 18px;
 margin-left: auto;
 margin-right: auto;
 left: 45%; 
 top: 465px; 
}


@media only screen and (max-width: 768px) {
 .circle {
  width: 20em;
  height: 20em;
  border: 1px solid black;
  position: relative;
  padding: 0;
  margin: 1em auto;
  border-radius: 50%;
  list-style: none;
  overflow: hidden;  
}
.wheel-part {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 50%;
  transform-origin: 0% 100%;
}
.text {
  position: absolute;
  left: -100%;
  width: 200%;
  height: 200%;
  text-align: center;
  display: block;
  transform: skewY(60deg) rotate(15deg);
  padding-top: 20px;  
  font-weight: bold;
  font-size: 18px;
}
.triangle-down {
  position: absolute;
	width: 0;
	height: 0;
	border-left: 15px solid transparent;
	border-right: 15px solid transparent;
	border-top: 50px solid black;
  left: 50%;
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
  top: 70px;
}
.spin-button {
 position: absolute;
 left: 36%;
 width: 120px;
 height: 40px;
 background-color: blue;
 border-radius: 30px;
 margin-top: 20px;
 cursor: pointer;
 outline: none;
 color: white;
 font-size: 18px;
 margin-left: auto;
 margin-right: auto;
 top: 400px  
} 
}