.char-counter-container {
    width: 650px;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
}

.char-counter-textarea {
    width: 650px;
    height: 300px;
    resize: none;
    outline: none;
    padding: 20px;
    font-size: 20px;
    font-family: inherit;
    font-weight: lighter;

}

.default-char-counter-results {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    font-size: 20px;
    color: navy;
}

.default-char-counter-result {
    width: 180px;
}

.char-counter-finder {
    width: 400px;
    height: 30px;
    font-size: 18px;
    resize: none;
    padding: 2px;
    font-family: inherit;
    font-weight: lighter;
}

.char-counter-finder-wrapper {
    margin-top: 20px;
}

.char-counter-result-wrapper {
    margin-top: 20px;
}

.char-counter-result {
    font-size: 18px;
    text-align: left;
}

.char-counter-header {
    color: purple;
    text-align: center;
}
