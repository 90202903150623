* {
  box-sizing: border-box;
}
h1, p {
  font-family: Lato;
}
body {
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: white;
}
.page-container {
  width: 500px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 50px;
  background-color: lightblue;
  border: 1px solid black;
}
.page-heading {
  width: 500px;
  height: 12%;
  background-color: rgb(21, 21, 44);
  padding: 3px 10px ; 
}
.level {
  float: right;
  width: 100px;
  height: 12%;
  text-align: center;
  background-color: white;
  color: purple;
  border: 2px solid black;
}
.page-heading, h3 {
  color: white;
}
.page-input, p, .chances {
  text-align: center;
  font-size: 12px;
}
.chances {
  background-color: red;
  color: white;
  padding: 3px;
  border-radius: 20px
}
.input-box {
width: 200px;
height: 50px;
margin-left: 150px;
padding: 10px 0px;
border: 2px solid rgb(21,21,44);
text-align: center;
font-size: 26px;
background-color: white;
}
.page-instructions {
  width: 100%;
  height: 100px;
  padding: 2px auto;
  overflow-y: auto;
  font-size: 14px;
}
.page-instructions::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE and Edge */
.page-instructions {
    -ms-overflow-style: none;
}
.page-buttons {
  margin-left: 70px;
}
button {
  margin: 10px;
  width: 100px;
  height: 40px;
  font-weight: bold;
  font-size: 15px;
  border: 2px solid black;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
}
.new-game {
  width: 503px;
  height: 564px;
  background-color: white;
  position: absolute; 
  top: 60px; 
  margin-left: -2px;
  text-align: center;
  border: 1px solid grey;
}
.ad-space {
  background-color: #e6e6e6;
  width: 100%;
  height: 67%;
  border: 1px solid grey;
}
.answer {
  color: blue;
  display: inline;
}
.delete {
  background-color: blue;
  color: white;
}
.enter {
  background-color: green;
  color: white
}
button:hover {
  border: 2px solid #f50;
  }
button[target="49"] {
  border: 2px solid #f50;
}
.congrats {
  color: purple;
}
.next-btn {
  width: 200px;
}
.score {
  background-color: white;
  color: red;
}
.top-rankers-container {
  position: absolute;
  width: 500px;
  height: 130px;
  background-color: white;
  overflow: auto;
}
.top-rankers-table {
  border-collapse: collapse;
}
.top-rankers {
  text-align: left;
  width: 160px;
  color: blue;
  overflow: hidden;
  font-size: 15px;
}
.top-rankers-heading {
  padding: 8px;
  text-align: left;
  width: 200px;
  color: red;
}

.user-name {
  position: absolute;
  width: 500px;
  height: 200px;
  margin-left: -1px;
  background-color: lightblue;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  text-align: center;
}
.user-name input {
  width: 250px;
  height: 40px;
  text-align: center;
  font-size: 17px;
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}
@media only screen and (max-width: 768px) {
  * {
  box-sizing: border-box;
}
h1, p {
  font-family: Lato;
}
body {
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: white;
}
  .page-container {
  width: 350px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 30px;
  background-color: lightblue;
  border: 1px solid black;
}
  .page-heading {
  width: 350px;
  height: 12%;
  background-color: rgb(21, 21, 44);
  padding: px 10px ; 
}
.page-heading, h3 {
  color: white;
}

.level {
  float: right;
  width: 60px;
  height: 20px;
  text-align: center;
  background-color: white;
  color: purple;
  border: 1px solid black;
  font-size: 14px;
}

.page-input, p, .chances {
  text-align: center;
  font-size: 12px;
}
.chances {
  background-color: red;
  color: white;
  padding: 3px;
  border-radius: 20px
}
.input-box {
width: 200px;
height: 50px;
margin-left: 80px;
padding: 10px 0px;
border: 2px solid rgb(21,21,44);
text-align: center;
font-size: 23px;
background-color: white;
}
.page-instructions {
  width: 100%;
  height: 100px;
  padding: 2px auto;
  overflow-y: auto;
  font-size: 14px;
}
.page-instructions::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE and Edge */
.page-instructions {
    -ms-overflow-style: none;
}
.page-buttons {
  margin-left: 55px;
}
button {
  margin: 10px;
  width: 70px;
  height: 30px;
  font-weight: bold;
  font-size: 13px;
  border: 2px solid black;
  border-radius: 5px;
  cursor: pointer;
}
.new-game {
  width: 352px;
  height: 500px;
  background-color: white;
  position: absolute; 
  top: 50px; 
  margin-left: -2px;
  text-align: center;
  border: 1px solid grey;
}
.answer {
  color: blue;
  display: inline;
}

.ad-space {
  background-color: #e6e6e6;
  width: 100%;
  height: 65%;
  border: 1px solid grey;
}
.delete {
  background-color: blue;
  color: white;
}
.enter {
  background-color: green;
  color: white
}
button:hover {
  border: 2px solid #f50;
  }
button[target="49"] {
  border: 2px solid #f50;
}
.congrats {
  color: purple;
  font-size: 14px;
}
.next-btn {
  width: 200px;
}
.score {
  background-color: white;
  color: red;
}
.top-rankers-container {
  position: absolute;
  width: 352px;
  height: 130px;
  background-color: white;
  overflow: auto;
}
.top-rankers-table {
  border-collapse: collapse;
}
.top-rankers {
  text-align: left;
  width: 110px;
  color: blue;
  overflow: hidden;
  font-size: 15px;
}
.top-rankers-heading {
  text-align: left;
  width: 110px;
  color: red;
}

.user-name {
  position: absolute;
  width: 350px;
  height: 200px;
  margin-left: -1px;
  background-color: lightblue;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  text-align: center;
}
.user-name input {
  width: 250px;
  height: 40px;
  text-align: center;
  font-size: 17px;
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}
.toppers-btn {
  margin: 10px;
  width: 100px;
  height: 30px;
  font-weight: bold;
  font-size: 13px;
  border: 2px solid black;
  border-radius: 5px;
  cursor: pointer;

}
.newgame-btn {
  margin: 10px;
  width: 100px;
  height: 30px;
  font-weight: bold;
  font-size: 13px;
  border: 2px solid black;
  border-radius: 5px;
  cursor: pointer;

}

}